.cont2 {
    background: rgb(185, 185, 185);
    background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
}

@media screen and (max-width: 499px) {

    .nftMain {
        margin-top: 70px;
    }

    .nftMainH {
        font-size: 40px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 12px;
        font-size: 14px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 16px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 1.5rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 95%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 16px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .nftMain {
        margin-top: 70px;
    }

    .nftMainH {
        font-size: 50px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 15px;
        font-size: 16px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 16px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 1.5rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 95%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 16px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .nftMain {
        margin-top: 70px;
    }

    .nftMainH {
        font-size: 50px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 18px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 15px;
        font-size: 16px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 18px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 2rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 90%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 16px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

    .nftMain {
        margin-top: 5%;
    }

    .nftMainH {
        font-size: 50px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 20px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 15px;
        font-size: 18px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 20px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 2rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 80%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 18px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .nftMain {
        margin-top: 5%;
    }

    .nftMainH {
        font-size: 50px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 20px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 15px;
        font-size: 18px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 20px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 2rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 70%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 18px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 1920px) {

    .nftMain {
        margin-top: 5%;
    }

    .nftMainH {
        font-size: 65px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 28px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 22px;
        font-size: 22px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 28px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 3rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 2px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 70%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 25px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

    .nftMain {
        margin-top: 5%;
    }

    .nftMainH {
        font-size: 95px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 38px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 26px;
        font-size: 33px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 36px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 5rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 4px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 70%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 35px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}

@media screen and (min-width: 3840px) {

    .nftMain {
        margin-top: 5%;
    }

    .nftMainH {
        font-size: 130px;
        text-align: center;
        font-weight: 500;
    }

    .nftMainP {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 2%;
        font-size: 57px;
        font-weight: 500;
    }

    .nftBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 40px;
        font-size: 50px;
        width: fit-content;
        background-color: #00B3FF;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        font-family: 'DM Sans';
        margin-top: 3%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .nftBtn:hover {
        /* Specify the final background color */
        background-color: #05D5D5;
        color: rgb(0, 0, 0);
    }

    .nftBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #00B3FF);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .nftBtn:hover::before {
        left: 0;
    }

    /*.............. FAQ ..............*/


    summary {
        font-size: 50px;
        background: rgba(19, 19, 19, 0);
        color: rgb(0, 0, 0);
        padding: 1rem;
        margin-top: 8rem;
        outline: none;
        text-align: left;
        cursor: pointer;
        border-bottom: 6px solid black;
        color: rgb(32, 32, 32);
        text-shadow: 0 0 10px #FFFFFF;
        font-weight: 500;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary~* {
        animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
        0% {
            opacity: 0;
            margin-top: -10px
        }

        100% {
            opacity: 1;
            margin-top: 0px
        }
    }

    .accordin {
        width: 70%;
        color: white;
        display: inline-block;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
    }

    .faq__content {
        color: rgb(0, 0, 0);
        text-align: left;
        font-size: 50px;
    }

    .faqTitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 70px;
        color: white;
    }

    .faqbg {
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        background: url('../images/faqbg.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .processMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #dadada;
    }
}