* {
    font-family: 'DM Sans';
}

a {
    color: rgb(255, 255, 255);

}

.notice {
    color: rgba(255, 255, 255, 0.726);
}

.loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.logo {
    cursor: pointer;
    width: 150px;
    animation: zoom 3s infinite;
    transform-origin: center;
}
